import React from 'react';
import LayoutTextPage from '../layout/layoutTextPage';
import { mq, theme } from '../theme';

class TermsPage extends React.PureComponent<{}, {}> { 
    public render() {
        return (
            <LayoutTextPage title='Algemene voorwaarden'>
                <div
                    css={mq({
                    maxWidth:'90vw',
                    margin:'auto',
                })}
                >
                    <div 
                      css={{
                        whiteSpace: 'pre-line',
                        paddingBottom: 24
                      }}
                      dangerouslySetInnerHTML={{
                        __html: `Privacy Policy
                        Smart Sales Company B.V., gevestigd aan de Professor W.H. Keesomlaan 12, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.
                        
                        Contactgegevens:
                        Smart Sales Company B.V.
                        Professor W.H. Keesomlaan 12 
                        1183DJ Amstelveen
                        030 249 81 81
                        
                        De Functionaris Gegevensbescherming van Smart Sales Company is te bereiken via backoffice@smartsalescompany.nl
                        
                        Persoonsgegevens die wij verwerken:
                        Smart Sales Company verwerkt uw persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze zelf aan ons verstrekt. Hieronder vindt u een overzicht van de persoonsgegevens die wij verwerken:
                        
                        – Voor- en achternaam
                        – E-mailadres
                        – Telefoonnummer
                        – Naam van uw organisatie
                        
                        Bijzondere en/of gevoelige persoonsgegevens die wij verwerken
                        Onze website heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de onlineactiviteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als u ervan overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via backoffice@smartsalescompany.nl, dan verwijderen wij deze informatie.
                        
                        Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken
                        Smart Sales Company verwerkt uw persoonsgegevens voor de volgende doelen:
                        – Werving en selectie database .
                        – U te kunnen bellen of e-mailen indien dit nodig is om de sollicitatie verder in behandeling te nemen.
                        – Om een overeenkomst of arbeidsovereenkomst met kandidaten aan te gaan.
                        
                        Geautomatiseerde besluitvorming
                        Smart Sales Company neemt niet op basis van geautomatiseerde verwerkingen besluiten over zaken die (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die worden genomen door computerprogramma’s of -systemen, zonder dat daar een mens (bijvoorbeeld een medewerker van Smart Sales Company) tussen zit.
                        
                        Hoe lang we persoonsgegevens bewaren
                        Smart Sales Company bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor uw gegevens worden verzameld.
                        
                        Delen van persoonsgegevens met derden
                        Smart Sales Company deelt uw persoonsgegevens met verschillende derden als dit noodzakelijk is voor het uitvoeren van de overeenkomst en om te voldoen aan een eventuele wettelijke verplichting. Met bedrijven die u gegevens verwerken in onze opdracht, sluiten wij een vewerkerkingsovereenkomst om te zorgen voor eenzelfde niveau van beveiliging en vertrouwelijkheid van uw gegevens. Smart Sales Company blijft verantwoordelijk voor deze verwerkingen. Dit doen wij alleen met uw nadrukkelijke toestemming.
                        
                        Cookies, of vergelijkbare technieken, die wij gebruiken
                        Smart Sales Company gebruikt alleen technische en functionele cookies. En analytische cookies die geen inbreuk maken op uw privacy. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen op uw computer, tablet of smartphone. De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en uw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld uw voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren. U kunt zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen via de instellingen van uw browser verwijderen.
                        
                        Gegevens inzien, aanpassen of verwijderen
                        U heeft het recht om uw persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heeft u het recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van uw persoonsgegevens door Smart Sales Company en heeft u het recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt indienen om de persoonsgegevens die wij van u beschikken in een computerbestand naar u of een ander, door u genoemde organisatie, te sturen. U kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens of verzoek tot intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens sturen naar backoffice@smartsalescompany.nl. Om er zeker van te zijn dat het verzoek tot inzage door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van uw privacy. We reageren zo snel mogelijk, maar binnen vier weken, op uw verzoek. Smart Sales Company wil u er tevens op wijzen dat u de mogelijkheid heeft om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
                        
                        Hoe wij persoonsgegevens beveiligen
                        Smart Sales Company neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via backoffice@smartsalescompany.nl`
                      }}
                    />
                </div>   
        </LayoutTextPage>
        )}
}

export default TermsPage;
